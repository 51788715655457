// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss";

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import Alpine from '@alpinejs/csp'
import Clipboard from "@ryangjchandler/alpine-clipboard"
import "phoenix_html";
import { Socket, LongPoll } from "phoenix";
import NProgress from "nprogress";
import { LiveSocket } from "phoenix_live_view";
import "photoviewer/dist/photoviewer.css";
import PhotoViewer from "photoviewer";

Alpine.plugin(Clipboard)
window.Alpine = Alpine
window.Alpine.start()

let Hooks = {};
const formatDateTime = (type, element) => {
  const monthNames = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };

  const twoChars = function (n) {
    return n < 10 ? `0${n}` : `${n}`;
  };
  const getForm = function (element) {
    if (element.tagName === "FORM") {
      return element;
    } else {
      return getForm(element.parentElement);
    }
  };

  const localDate = (str) => {
    const m = str.match(/(....).(..).(..)(?:.(..).(..))?/);
    if (m[4]) {
      return new Date(Date.UTC(m[1], parseInt(m[2] - 1), m[3], m[4], m[5]));
    } else {
      return new Date(Date.UTC(m[1], parseInt(m[2] - 1), m[3], 12));
    }
  };

  try {
    const textContent = element.textContent.trim().replaceAll(/[^0-9 :/TZ-]/g, "");
    if (!textContent) {
      return;
    }
    const date = localDate(textContent);

    const dateStr = `${twoChars(date.getDate())} ${monthNames[twoChars(date.getMonth() + 1)]} ${date.getFullYear()}`;
    const timeStr = `${twoChars(date.getHours())}:${twoChars(date.getMinutes())}`;
    element.textContent = type === "datetime" ? `${dateStr}, ${timeStr}` : dateStr;
  } catch (error) {
    console.warn(error);
  }
};

Hooks.DateFormat = {
  mounted() {
    formatDateTime("date", this.el);
  },
  updated() {
    formatDateTime("date", this.el);
  },
};

Hooks.DateTimeFormat = {
  mounted() {
    formatDateTime("datetime", this.el);
  },
  updated() {
    formatDateTime("datetime", this.el);
  },
};

Hooks.TextArea = {
  updated() {
    this.el.value = this.el.dataset.pendingVal;
  },
};

Hooks.ClearFileUploadSP = {
  mounted() {
    const el = this.el;
    el.addEventListener("click", (ev) => {
      const id = el.getAttribute("phx-value-upload_ref");
      const input = document.getElementById(id);
      input.value = "";
    });
  },
};

Hooks.ScrollToBottom = {
  mounted() {
    const el = this.el;
    el.scrollTop = el.scrollHeight;
  },
  updated() {
    const el = this.el;
    el.scrollTop = el.scrollHeight;
  },
};

const mountCopyToClipboard = (el) => {
  el.addEventListener("click", (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const id = el.getAttribute("data-target");
    const accountIdText = document.getElementById(id);
    accountIdText.select();
    accountIdText.setSelectionRange(0, 99999);
    // will run if its secure context 
    navigator.clipboard.writeText(accountIdText.value)
  });
};

const loadPhotoViewerHandle = (e) => {
  const opts = {
    footerToolbar: ["zoomIn", "zoomOut", "fullscreen", "actualSize", "rotateRight"],
  };

  const el = e.target;
  const href = el.getAttribute("href");
  const title = el.getAttribute("title");

  const items = [{ src: href, title: title || href }];

  e.preventDefault();
  new PhotoViewer(items, opts);
};

const loadPhotoViewer = (el) => {
  el.removeEventListener("click", loadPhotoViewerHandle);
  el.addEventListener("click", loadPhotoViewerHandle);
};

Hooks.PhotoViewer = {
  mounted() {
    loadPhotoViewer(this.el);
  },
  updated() {
    loadPhotoViewer(this.el);
  },
};

Hooks.CopyToClipboard = {
  mounted() {
    mountCopyToClipboard(this.el);
  },
  updated() {
    mountCopyToClipboard(this.el);
  },
};

// Hooks.TempDebug = {
//   mounted() {
//     const href = this.el.getAttribute('href'); // Properly get the 'href' attribute
//     const fullUrl = "http://partner.local.settld.care:4003/" + href;

//     console.log(fullUrl); // Log the full URL
//     window.open(fullUrl, '_blank'); // Open in a new tab
//   }
// }

let cspNonce = document.querySelector("meta[name='csp-nonce']").getAttribute("content");
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

let liveSocket = new LiveSocket("/service_provider/live", Socket, {
  params: { _csrf_token: csrfToken, _csp_nonce: cspNonce },
  hooks: Hooks,
  dom: {
    onBeforeElUpdated(from, to) {
      if (from.__x) {
        window.Alpine.clone(from.__x, to);
      }
    },
  },
  //  transport: LongPoll,
});


// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", (info) => NProgress.start());
window.addEventListener("phx:page-loading-stop", (info) => NProgress.done());

// connect if there are any LiveViews on the page
liveSocket.connect();

// expose liveSocket on window for web console debug logs and latency simulation:
liveSocket.enableDebug();
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket;
